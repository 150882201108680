<div class="header">
  <div class="close-filter-container">
    <button theme="icon" (click)="hideSidebar()" icon="times"></button>
  </div>
<novo-search placeholder="Keyword Search" alwaysOpen="true" [(ngModel)]="keyword" (ngModelChange)="searchOnDelay()"></novo-search>
</div>
<novo-loading *ngIf="loading"></novo-loading>
<ng-container *ngIf="!loading">
  <div class="filters">
    <app-sidebar-filter field="publishedCategory(id,name)" title="Category" [filter]="filter" (checkboxFilter)="updateFilter('publishedCategory', $event)" ></app-sidebar-filter>
    <app-sidebar-filter field="address(state)" title="State" [filter]="filter" (checkboxFilter)="updateFilter('address(state)', $event)"></app-sidebar-filter>
    <app-sidebar-filter field="address(city)" title="City" [filter]="filter" (checkboxFilter)="updateFilter('address(city)', $event)"></app-sidebar-filter>
  </div>
</ng-container>

<section class="credits">
    <span class="powered-by">Powered By</span>
    <a target="_blank" href="http://www.bullhorn.com/">
        <img class="bullhorn" src="assets/logo.svg">
    </a>
</section>

import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../settings/settings.service';
import { of } from 'rxjs';
var SearchService = /** @class */ (function () {
    function SearchService(http, settings) {
        this.http = http;
        this.settings = settings;
    }
    Object.defineProperty(SearchService.prototype, "baseUrl", {
        get: function () {
            var service = SettingsService.settings.service;
            var port = service.port ? service.port : 443;
            var scheme = "http" + (port === 443 ? 's' : '');
            return scheme + "://public-rest" + service.swimlane + ".bullhornstaffing.com:" + port + "/rest-services/" + service.corpToken;
        },
        enumerable: true,
        configurable: true
    });
    SearchService.prototype.getjobs = function (filter, params, count) {
        if (params === void 0) { params = {}; }
        if (count === void 0) { count = 30; }
        var queryArray = [];
        params.where = "(isOpen=true) AND (isDeleted=false)" + this.formatAdditionalCriteria(false) + this.formatFilter(filter, false);
        params.fields = SettingsService.settings.service.fields;
        params.count = count;
        params.sort = SettingsService.settings.additionalJobCriteria.sort;
        params.showTotalMatched = true;
        for (var key in params) {
            queryArray.push(key + "=" + params[key]);
        }
        var queryString = queryArray.join('&');
        return this.http.get(this.baseUrl + "/query/JobBoardPost?" + queryString);
    };
    SearchService.prototype.openJob = function (id) {
        return this.http.get(this.baseUrl + "/query/JobBoardPost?where=(id=" + id + ")&fields=" + SettingsService.settings.service.fields);
    };
    SearchService.prototype.getCurrentJobIds = function (filter, ignoreFields) {
        var queryArray = [];
        var params = {};
        params.query = "(isOpen:1) AND (isDeleted:0)" + this.formatAdditionalCriteria(true) + this.formatFilter(filter, true, ignoreFields);
        params.count = "500";
        params.fields = 'id';
        params.sort = 'id';
        for (var key in params) {
            queryArray.push(key + "=" + params[key]);
        }
        var queryString = queryArray.join('&');
        return this.http.get(this.baseUrl + "/search/JobOrder?" + queryString);
    };
    SearchService.prototype.getAvailableFilterOptions = function (ids, field) {
        var params = {};
        var queryArray = [];
        if (ids.length > 0) {
            params.where = "id IN (" + ids.toString() + ")";
            params.count = "500";
            params.fields = field + ",count(id)";
            params.groupBy = field;
            switch (field) {
                case 'publishedCategory(id,name)':
                    params.orderBy = 'publishedCategory.name';
                    break;
                case 'address(state)':
                    params.orderBy = 'address.state';
                    break;
                case 'address(city)':
                    params.orderBy = 'address.city';
                    break;
                default:
                    params.orderBy = '-count.id';
                    break;
            }
            for (var key in params) {
                queryArray.push(key + "=" + params[key]);
            }
            var queryString = queryArray.join('&');
            return this.http.get(this.baseUrl + "/query/JobBoardPost?" + queryString); // tslint:disable-line
        }
        else {
            return of({ count: 0, start: 0, data: [] });
        }
    };
    SearchService.prototype.formatAdditionalCriteria = function (isSearch) {
        var field = SettingsService.settings.additionalJobCriteria.field;
        var values = SettingsService.settings.additionalJobCriteria.values;
        var query = '';
        var delimiter = isSearch ? '"' : '\'';
        var equals = isSearch ? ':' : '=';
        if (field && values.length > 0 && field !== '[ FILTER FIELD HERE ]' && values[0] !== '[ FILTER VALUE HERE ]') {
            for (var i = 0; i < values.length; i++) {
                if (i > 0) {
                    query += " OR ";
                }
                else {
                    query += ' AND (';
                }
                query += "" + field + equals + delimiter + values[i] + delimiter;
            }
            query += ')';
        }
        return query;
    };
    SearchService.prototype.formatFilter = function (filter, isSearch, ignoreFields) {
        if (ignoreFields === void 0) { ignoreFields = []; }
        var additionalFilter = '';
        for (var key in filter) {
            if (!ignoreFields.includes(key)) {
                var filterValue = filter[key];
                if (typeof filterValue === 'string') {
                    additionalFilter += " AND (" + filterValue + ")";
                }
                else if (filterValue.length) {
                    additionalFilter += " AND (" + filterValue.join(' OR ') + ")";
                }
            }
        }
        return additionalFilter.replace(/{\?\^\^equals}/g, isSearch ? ':' : '=').replace(/{\?\^\^delimiter}/g, isSearch ? '"' : '\'');
    };
    return SearchService;
}());
export { SearchService };

<novo-loading *ngIf="loading"></novo-loading>
<div class="mobile-filter-container">
<button theme="secondary" (click)="openSidebar()" icon="filter" side="left">{{ 'FILTER' | translate }}</button>

</div>
<div class="open-positions">
    <span class="open-positions-text">{{ 'OPEN_POSITIONS' | translate }} ({{total}})</span>
</div>
<novo-list direction="vertical" *ngIf="!loading && jobs.length > 0">

<div *ngFor="let job of jobs" class="job-card" [routerLink]="'jobs/' + job.id" (click)="loadJob(job.id)">
    <novo-list-item>
        <item-header>
            <item-title><a [href]="'jobs/' + job.id" [routerLink]="'jobs/' + job.id">{{ job.title }}</a></item-title>
        </item-header>
        <item-header-end>
            <span class="mid-card" *ngIf="job.address.city || job.address.state">
                <i class="bhi-location"></i>
                {{ job.address.city }} <span *ngIf="job.address?.city && job.address?.state">,&nbsp;</span> {{ job.address.state }}
            </span>
            <ng-container *ngFor="let chip of jobInfoChips" [ngSwitch]="chip.type || 'string'">
                <span class="mid-card" *ngIf="job[chip] || job[chip?.field]">
                    <ng-container *ngSwitchCase="'string'">
                        {{ job[chip?.field] || job[chip] }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'mediumDate'">
                        {{ job[chip?.field] | date: 'mediumDate'}}
                    </ng-container>
                    <ng-container  *ngSwitchCase="'dateTime'">
                        {{ job[chip?.field] | date: 'medium'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'USD'">
                        {{ job[chip?.field] | currency: 'USD'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'GBP'">
                        {{ job[chip?.field] | currency: 'GBP'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'EUR'">
                        {{ job[chip?.field] | currency: 'EUR'}}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{ job[chip?.field] || job[chip] }}
                    </ng-container>
                </span>
            </ng-container>
        </item-header-end>
    </novo-list-item>
    <span class="category" *ngIf="job.publishedCategory && showCategory">
        {{ job.publishedCategory?.name }}
    </span>
    <span class="description">
        {{ job.publicDescription | stripHtml}}
    </span>
</div>

</novo-list>
<div class="load-more-button" *ngIf="!loading && moreAvailable">
  <button theme="secondary" (click)="loadMore()" inverse>Load More</button>
</div>
<div class="empty-list" *ngIf="!loading && jobs.length === 0">
  <i class="bhi-search-question"></i>
  {{ 'NO_MATCHING_JOBS' | translate }}
</div>
